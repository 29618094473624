/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, Select } from '@intraversa-lab/styleguide';
import { CheckboxRHF } from 'components/FormRHF/CheckboxRHF';
import { SelectRHF } from 'components/FormRHF/InputFieldRHF copy';
import { TextFieldRHF } from 'components/FormRHF/TextFieldRHF';
import { queryClient } from 'configs/queryClient';
import { IPayloadUnitaryRenewal } from 'domains/ActionConfig/actionTypes';
import { usePostAction } from 'domains/ActionConfig/useCases/usePostAction';
import { useGetPlans, useGetRecipients } from 'domains/PagarMe';
import { QueryKeys } from 'infra';
import { FormProvider, useForm } from 'react-hook-form';

import { unitarySchema } from './UnitarySchema';

interface IModalUnitaryRenawelProps {
  isOpen: boolean;
  onClose: () => void;
}

interface dataPayload {
  assinatura: number;
  nome: string;
  email: string;
}

export const ModalUnitaryRenawel: React.FC<IModalUnitaryRenawelProps> = ({
  isOpen,
  onClose
}) => {
  const { data: plans } = useGetPlans();
  const { data: recipients } = useGetRecipients();

  const methods = useForm<dataPayload>({
    resolver: zodResolver(unitarySchema),
    defaultValues: {
      assinatura: null,
      nome: '',
      email: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    formState: { errors },
    watch
  } = methods;

  const { postUnitaryRenewal } = usePostAction({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      onClose();
    }
  });

  function onSubmit(data: dataPayload) {
    const result: IPayloadUnitaryRenewal = {
      clientEmail: data.email,
      clientName: data.nome,
      subscription: data.assinatura
    };
    postUnitaryRenewal(result);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}
      >
        <div className="z-10">
          <div className="space-y-4 mb-8">
            <h1 className="text-[#161F28] text-4xl">Cancelamento Unitária</h1>
            <h3 className="text-[#75808A] text-base">
              Preencha os campos abaixo para adicionar um cancelamento de forma
              unitária à tabela.
            </h3>
          </div>

          <FormProvider {...methods}>
            <div className="space-y-4 mb-8">
              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Assinatura</label>
                <div className="flex-1">
                  <div>
                    <TextFieldRHF mask="number" name="assinatura" helperText />
                    {errors.assinatura && (
                      <p className="text-xs text-error">Campo Obrigatório</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Nome</label>
                <div className="flex-1">
                  <TextFieldRHF name="nome" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Email</label>
                <div className="flex-1">
                  <TextFieldRHF name="email" />
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Button variant="outlined" className="flex-1" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className="flex-1"
                type="submit"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Adicionar
              </Button>
            </div>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
