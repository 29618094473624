import { Main } from '../pages/Main';

export const privateRoutes = [
  {
    path: '/oeste-cancellation/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  }
];
