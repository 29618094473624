import axios from 'axios';

const localStorageUrl = localStorage.getItem('@PORTICO:baseurl-rpa');

export const api = axios.create({
  baseURL: `${localStorageUrl}api`
});

axios.interceptors.request.use(req => {
  const localStorageUrlInterceptor = localStorage.getItem(
    '@PORTICO:baseurl-rpa'
  );
  req.baseURL = `${localStorageUrlInterceptor}api`;
  return req;
});
