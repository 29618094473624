import React, { useEffect, useRef, useState } from 'react'

import { Button, Modal, toast } from '@intraversa-lab/styleguide'
import { IconSave } from 'assets/IconSave'
import { IconUpload } from 'assets/IconUpload'
import { IResponseModelCSV } from 'domains/ActionConfig/actionTypes'
import { useGetModelCSV } from 'domains/ActionConfig/useCases'
import { useUploadCSV } from 'domains/ActionConfig/useCases/useUploadCSV'
import { downloadDocumentNavigator } from 'utils/downlaodCSV'

import { Dropzone } from './components/Dropzone'

interface IModalUnitaryRenawelProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ModalUpload: React.FC<IModalUnitaryRenawelProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [file, setFile] = useState<File>()
  const dropzoneRef = useRef(null)

  const [progress, setProgress] = useState(0)
  const [upload, setUpload] = useState(false)

  const { onGetModelCSV } = useGetModelCSV({
    onSuccess: (data: IResponseModelCSV) => {
      downloadDocumentNavigator(data.base64)
    },
  })
  const { onUploadCSV, isLoading: isLoadingUpload } = useUploadCSV({
    onSuccess: () => {
      setTimeout(() => {
        setUpload(false)
        setProgress(100)
      }, 1000)

      setTimeout(() => {
        onClose()
        onSuccess()
        toast({
          type: 'success',
          text: 'Sucesso',
          description: 'Upload realizado com sucesso',
        })
      }, 2500)
    },
  })

  const handleOnDownloadCSV = () => {
    onGetModelCSV()
  }

  const handleUpload = () => {
    const formData = new FormData()
    formData.append('file', file)

    setUpload(true)
    onUploadCSV(formData as any)
  }

  useEffect(() => {
    if (upload) {
      const timer = setInterval(() => {
        if (progress < 100) {
          setProgress((old) => old + 5)
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [upload, progress])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}
      >
        <div>
          <div className="space-y-4 mb-8">
            <h1 className="text-[#161F28] text-4xl">Upload arquivo CSV</h1>
            <h3 className="text-[#75808A] text-base ">
              Selecione um arquivo CSV ou arraste e solte na área abaixo.
            </h3>
          </div>

          <div className="flex gap-4" style={{ width: 'fit-content' }}>
            <Button
              className="flex-1 gap-3 whitespace-nowrap"
              variant="outlined"
              style={{ textTransform: 'none' }}
              onClick={() => dropzoneRef.current?.click()}
            >
              <IconUpload />
              Upload arquivo CSV
            </Button>

            <Button
              className="flex-1 gap-3 whitespace-nowrap "
              variant="outlined"
              style={{ textTransform: 'none' }}
              onClick={handleOnDownloadCSV}
            >
              <IconSave />
              Baixar modelo CSV
            </Button>
          </div>

          <div>
            <Dropzone
              onChange={(file) => setFile(file)}
              progress={progress}
              ref={dropzoneRef}
            />
          </div>

          <div className="flex items-center space-x-4">
            <Button
              variant="outlined"
              className="flex-1"
              onClick={onClose}
              style={{ textTransform: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              className="flex-1"
              style={{ textTransform: 'none' }}
              disabled={!file || isLoadingUpload}
              onClick={handleUpload}
            >
              Adicionar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
