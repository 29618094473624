import { QueryKeys } from 'infra'
import { useQuery } from 'react-query'

import { flowService } from '../flowService'

export function useGetRpaFlow({
  page = 0,
  offset = 10,
  sort = 'init_flow',
}: {
  page: number
  offset: number
  sort?: string
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.GetActionRpa, { page, offset, sort }],
    queryFn: async () => {
      const response = await flowService.getRpaFlow({
        page,
        offset,
        sort,
      })

      return response
    },
  })

  return {
    flows: data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
