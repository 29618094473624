import { api } from 'configs'
import { endpointsUploader } from 'infra/endpoints'

import { IResponseAction } from '../ActionConfig/actionTypes'

async function getRpaConfigByRpaId(rpaId: string) {
  return await api.get(
    `${endpointsUploader.getRpaConfigByRpaId.replace(':rpaId', rpaId)}`,
  )
}

async function getRpaAction({
  page = 0,
  offset = 10,
  sort = 'created_at',
}: {
  page: number
  offset: number
  sort: string
}): Promise<IResponseAction[]> {
  const response = await api.get(endpointsUploader.getRpaActionEndpoint, {
    params: { page, offset, sort },
  })

  return response.data
}

export const rpaConfigApi = {
  getRpaConfigByRpaId,
  getRpaAction,
}
