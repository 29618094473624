/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'

import { TextField, ButtonPortico, If } from '@intraversa-lab/styleguide'
import { IconEdit, IconReload } from 'components/Icons/Icons'
import { usePostTestConnection } from 'domains/PagarMe/useCases/usePostTestConnection'
import { IRpaConfigResponse } from 'domains/RpaConfig/rpaConfigTypes'

import {
  IModalConnectivityType,
  ModalConnectivity,
} from './components/ModalConnectivity'
import {
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
} from './meta/schedulerConfigTypes'
import * as S from './styles'

export interface CardIntegrationProps {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES
  data: IRpaConfigResponse['config']['outbounds'][0]['credential']
  configId: string
}

export const CardIntegration: React.FC<CardIntegrationProps> = ({
  type,
  data,
}) => {
  const isEmail = type === 'EMAIL'
  const [showLabelButton, setShowLabelButton] = useState(false)
  const [showTestingConnectivity, setShowTestingConnectivity] = useState(false)

  const {
    onTestConnection,
    isLoading: isLoadingTestConnection,
    isSuccess: isSuccessConnection,
    isError,
  } = usePostTestConnection()

  const configSelected = schedulerConfigTypes.find(
    (config) => config.type === type,
  )

  const typeConnection: IModalConnectivityType = useMemo(() => {
    if (isError) {
      return 'ERROR'
    }
    if (isSuccessConnection) {
      return 'SUCCESS'
    }
    return 'PENDING'
  }, [isSuccessConnection, isLoadingTestConnection, isError])

  const handleTestConnection = () => {
    setShowTestingConnectivity(true)
    onTestConnection()
  }

  return (
    <S.CollapseContainer>
      <S.Wrapper>
        <S.CollapseHeader>
          <div>
            <S.TextLg>{configSelected?.title}</S.TextLg>
            <S.TextDescription>
              Ultima edição 20/11/2023 09:34:01
            </S.TextDescription>
          </div>

          <S.WrapperButtons>
            <ButtonPortico
              style={{ height: '40px !important' }}
              variant="outlined"
              onClick={handleTestConnection}
              startIcon={<IconReload />}
              onMouseEnter={() => setShowLabelButton(true)}
              onMouseLeave={() => setShowLabelButton(false)}
            >
              {showLabelButton && 'Testar Conectividade'}
            </ButtonPortico>

            {/* <div className="middle-button">
              <ButtonPortico
                startIcon={<IconEdit />}
                variant="outlined"
                // onClick={() => onOpenModal('CONNECTION')}
              >
                Editar credenciais
              </ButtonPortico>
            </div> */}
          </S.WrapperButtons>
        </S.CollapseHeader>

        <S.CollapseContent>
          <If condition={!isEmail}>
            {configSelected?.fields.map((item) => (
              <S.WrapperInput key={item.name}>
                <S.TextLg className="labelInput">{item.label}</S.TextLg>
                <TextField
                  type={item.typeInput}
                  showIconPassword={false}
                  defaultValue={(data?.data as any)?.[item.name]}
                  disabled
                />
              </S.WrapperInput>
            ))}
          </If>
        </S.CollapseContent>
      </S.Wrapper>

      <ModalConnectivity
        isOpen={showTestingConnectivity}
        onClose={() => setShowTestingConnectivity(false)}
        status={typeConnection as any}
      />
    </S.CollapseContainer>
  )
}
