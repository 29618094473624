import React from 'react'

export const IconPlus = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.4375 6.5625H0.75V5.4375H5.4375V0.75H6.5625V5.4375H11.25V6.5625H6.5625V11.25H5.4375V6.5625Z"
        fill="#59636B"
      />
    </svg>
  )
}
