import React, { useState } from 'react'

import { IconArrowDown } from 'assets/IconArrowDown'
import { IconArrowTop } from 'assets/IconArrowTop'
import { IconInfo } from 'assets/IconInfo'

interface IProps {
  description: string
}

export const ButtonInfo = ({ description }: IProps) => {
  const [showDrop, setShowDrop] = useState(false)

  return (
    <div>
      <div
        onClick={() => setShowDrop(!showDrop)}
        className="pt-1 pb-1 pl-1 pr-1 rounded-[16px] border border-gray-300 relative"
        style={{ width: 'fit-content' }}
      >
        <button className="flex gap-2 items-center">
          <IconInfo />

          {showDrop ? <IconArrowTop /> : <IconArrowDown />}
        </button>
      </div>

      {showDrop && (
        <div className="bg-white text-left z-[100] max-w-[180px] mt-2 rounded-[4px] py-[12px] px-[16px] absolute shadow-md text-xs">
          <p>{description}</p>
        </div>
      )}
    </div>
  )
}
