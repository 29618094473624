import { useCallback, useMemo, useState } from 'react'

import { Stack } from '@mui/material'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { CardChooseConfig } from 'components/CardChooseConfig'
import { CardIntegration } from 'components/ContainerCollapse/ContainerCollapse'
import {
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes'
import { IRpaConfigResponse } from 'domains/RpaConfig/rpaConfigTypes'
import { useGetConfigRpa } from 'domains/RpaConfig/useCases'
import { useParams } from 'react-router-dom'

type CardSelectedType = IRpaConfigResponse['config']['outbounds'][0]

export const Configuracoes = () => {
  const { rpaId } = useParams()

  const { data: rpaConfig, isLoading: isLoadingConfigRpa } = useGetConfigRpa({
    rpaId,
    enabled: true,
  })

  console.log(rpaConfig)

  const [cardSelected, setCardSelected] = useState<CardSelectedType>(
    {} as CardSelectedType,
  )
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([])

  const showDetailsConfig = useMemo(() => {
    return !!cardSelected?.name
  }, [cardSelected])

  const handleSelectCard = useCallback(
    (type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES) => {
      const findConfig = rpaConfig?.config?.outbounds?.find(
        (item) => item.name === type,
      )

      const informationsConfig = schedulerConfigTypes.find(
        (config) => config.type === type,
      )

      setBreadcrumbs([
        {
          name: 'Configurações',
          value: 'config',
        },
        {
          name: informationsConfig.title,
          value: findConfig.name,
        },
      ])

      if (findConfig) {
        setCardSelected(findConfig)
      }
    },
    [rpaConfig],
  )

  const handleChangeBreadcrumbs = useCallback((value: string) => {
    if (value === 'config') {
      setBreadcrumbs([])
      setCardSelected({} as CardSelectedType)
    }
  }, [])

  return (
    <div className="pt-6 pb-6 pl-6 pr-6">
      {!showDetailsConfig && (
        <Stack spacing={'16px'}>
          {rpaConfig?.config?.outbounds.map((config) => (
            <CardChooseConfig
              key={config.id}
              type={config.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
              onSelected={handleSelectCard}
            />
          ))}
        </Stack>
      )}

      {showDetailsConfig && (
        <>
          <Breadcrumbs
            items={breadcrumbs}
            active={cardSelected?.name}
            onChange={handleChangeBreadcrumbs}
          />

          <CardIntegration
            key={cardSelected.id}
            type={cardSelected.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
            data={cardSelected.credential}
            configId={cardSelected.id}
          />
        </>
      )}
    </div>
  )
}
