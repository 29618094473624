import { useState } from 'react';

import { If } from '@intraversa-lab/styleguide';
import Tab from '@mui/material/Tab';
import TabsMUI from '@mui/material/Tabs';
import { IconClock } from 'assets/IconClock';
import { HeaderMain } from 'components/HeaderMain/HeaderMain';

import { Assinaturas } from './containers/assinaturas/Assinaturas';
import { Configuracoes } from './containers/configuracoes/Configuracoes';
import { Resumo } from './containers/resumo';

export const Main = () => {
  const [value, setValue] = useState('assinaturas');

  // const { rpaId } = useParams()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="w-screen h-screen bg-gray-100">
      <div className="w-screen pt-6 pl-8 pr-8 mb-8">
        <HeaderMain />
      </div>

      <div className="pl-8 pr-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center ">
            <h1 className="font-semibold text-[32px]">Cancelamento</h1>
            <div className="ml-10 pl-3 pr-3 rounded-sm bg-green-100 h-7 flex justify-center items-center">
              <p className="text-[16px] font-normal text-green1 h-6">Ativo</p>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <IconClock />
            <span className="text-[16px] font-medium text-gray-600">
              Última atualização
            </span>
            <span className="font-medium text-gray-600">
              20/11/2023 09:34:01
            </span>
          </div>
        </div>
      </div>

      <div
        className="w-screen mt-4"
        style={{ height: '1px', backgroundColor: '#EFF2F4' }}
      ></div>

      <div className="pl-8 pr-8">
        <div className="flex items-center justify-between">
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="resumo" label="Resumo de Operações" />

            <Tab
              value="assinaturas"
              label="Assinaturas"
              style={{ marginLeft: 32 }}
            />

            <Tab
              value="configuracoes"
              label="Configurações"
              style={{ marginLeft: 32 }}
            />
          </TabsMUI>
        </div>
      </div>

      <div
        className="w-screen"
        style={{ height: '1px', backgroundColor: '#EFF2F4' }}
      ></div>

      <div className="mt-4">
        <If condition={value === 'resumo'}>
          <Resumo />
        </If>

        <If condition={value === 'assinaturas'}>
          <Assinaturas />
        </If>

        <If condition={value === 'configuracoes'}>
          <Configuracoes />
        </If>
      </div>
    </div>
  );
};
