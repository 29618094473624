import { toast } from '@intraversa-lab/styleguide';
import { MutationOptions } from 'configs/useMutation';
import { useMutation } from 'react-query';

import { actionService } from '../actionService';
import { IPayloadUnitaryRenewal, IResponseUnitaryRenwal } from '../actionTypes';

type payload = IPayloadUnitaryRenewal;

export function usePostAction(
  options?: MutationOptions<IResponseUnitaryRenwal[]>
) {
  const mutation = useMutation<IResponseUnitaryRenwal[], Error, payload>({
    mutationFn: (payload: payload) =>
      actionService.postRpaActionUnitaryRenewal(payload),
    retry: false,
    onError: error => {
      if (options?.onError) {
        options.onError(error.message);
      }
    },
    onSuccess: authCredentials => {
      if (options?.onSuccess) {
        toast({
          type: 'success',
          text: 'Cadastrado com sucesso',
          description: 'Cancelamento unitária cadastrada com sucesso.'
        });
        options.onSuccess(authCredentials);
      }
    }
  });

  return {
    isLoading: mutation.isLoading,
    postUnitaryRenewal: (payload: payload) => mutation.mutate(payload),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError
  };
}
