import React, { useState } from 'react'

import { IconClose } from 'assets/IconClose'
import { IconFilter } from 'assets/IconFilter'

import * as S from './styles'

interface IFilterProps {
  filterBy: (v: string) => void
  options: Array<String>
}

export const FilterTo = ({ filterBy, options }: IFilterProps) => {
  const [showDropFilter, setShowDropFilter] = useState(false)
  const [filtrarPor, setFiltrarPor] = useState(null)

  filterBy(filtrarPor !== null ? filtrarPor : null)

  function resetFilterTo() {
    setFiltrarPor(null)
    setShowDropFilter(false)
  }

  return (
    <div>
      <S.ContentButtonFilter>
        <S.ButtonFilter isFilter={!!filtrarPor || showDropFilter}>
          <div onClick={() => setShowDropFilter(!showDropFilter)}>
            <IconFilter />
            Filtrar por
          </div>

          {!!filtrarPor && (
            <div className="icon-close-filter" onClick={resetFilterTo}>
              <IconClose />
            </div>
          )}
        </S.ButtonFilter>

        {showDropFilter && (
          <>
            <div className="drop-filter">
              <span>Filtros</span>
              <S.ContentFilteButton>
                {options.map((op: string, index) => (
                  <label key={index}>
                    {op}
                    <input
                      type="radio"
                      id={op}
                      name="filtro"
                      value={op}
                      checked={filtrarPor === op}
                      onChange={(e) => setFiltrarPor(e.target.value)}
                    />
                  </label>
                ))}
              </S.ContentFilteButton>
            </div>

            <div
              onClick={() => setShowDropFilter(!showDropFilter)}
              className="overlay-drop-filter"
            ></div>
          </>
        )}
      </S.ContentButtonFilter>
    </div>
  )
}
