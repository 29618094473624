import { ThemeProvider } from '@emotion/react'
import { ToastContainer, theme } from '@intraversa-lab/styleguide'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { queryClient } from 'configs/queryClient'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { Routes } from './routes/Routes'

export default function Root() {
  // useEffect(() => {
  //   return () => {
  //     queryClient.clear()
  //   }
  // }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <ToastContainer />
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}
