import { QueryKeys } from 'infra'
import { useQuery } from 'react-query'

import { actionService } from '../actionService'

export function useGetAllRpaAction({
  value = '',
  finalDate = '',
  initialDate = '',
  status = '',
  page = 0,
  offset = 10,
  sort = 'created_at',
}: {
  value?: string
  finalDate?: string
  initialDate?: string
  status?: string
  page?: number
  offset?: number
  sort?: string
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [
      QueryKeys.GetFindAllActionRpa,
      { value, finalDate, initialDate, page, offset, sort },
    ],
    queryFn: async () => {
      const response = await actionService.getAllRpaAction({
        value,
        finalDate,
        initialDate,
        status,
        page,
        offset,
        sort,
      })

      return response
    },
  })

  return {
    actions: data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
