/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'configs'
import { endpointsUploader } from 'infra'

import {
  IPayloadUnitaryRenewal,
  IResponseAction,
  IResponseDownload,
  IResponseModelCSV,
  IResponseUnitaryRenwal,
} from './actionTypes'

async function getRpaAction({
  page,
  offset,
  sort,
}: {
  page: number
  offset: number
  sort?: string
}): Promise<IResponseAction> {
  const response = await api.get(endpointsUploader.getRpaActionEndpoint, {
    params: {
      page,
      offset,
      sort,
    },
  })
  return response.data
}

async function getFindAllRpaAction({
  value = '',
  finalDate = '',
  initialDate = '',
  status = '',
  page = 0,
  offset = 10,
  sort = 'created_at',
}: {
  value?: string
  finalDate?: string
  initialDate?: string
  status?: string
  page?: number
  offset?: number
  sort?: string
}): Promise<IResponseAction> {
  const response = await api.get(endpointsUploader.getRpaActionEndpoint, {
    params: {
      value,
      finalDate,
      initialDate,
      status,
      page,
      offset,
      sort,
    },
  })
  return response.data
}

async function postRpaActionUnitaryRenewal(
  payload: IPayloadUnitaryRenewal,
): Promise<IResponseUnitaryRenwal[]> {
  const response = await api.post(
    endpointsUploader.getRpaActionEndpoint,
    payload,
  )
  return response.data
}

async function getModelCSV(): Promise<IResponseModelCSV> {
  const response = await api.get(endpointsUploader.getModelCSV)
  return response.data
}

async function postUploadCSV(file: File): Promise<any> {
  const response = await api.post(endpointsUploader.postCSV, file)
  return response.data
}

async function getDownloadAction(): Promise<IResponseDownload> {
  const response = await api.get(endpointsUploader.postDownloadAction)
  return response.data
}

async function postCleanAll(): Promise<any> {
  const response = await api.post(endpointsUploader.postCleanAll)
  return response.data
}

export const actionApi = {
  getRpaAction,
  getFindAllRpaAction,
  postRpaActionUnitaryRenewal,
  getModelCSV,
  postUploadCSV,
  getDownloadAction,
  postCleanAll,
}
