/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'configs'
import { endpointsUploader, endpointsFlow } from 'infra'

import { IResponseFlow } from './flowTypes'

async function getRpaFlow({
  page,
  offset,
  sort,
}: {
  page: number
  offset: number
  sort?: string
}): Promise<IResponseFlow> {
  const response = await api.get(endpointsUploader.getRpaFlow, {
    params: {
      page,
      offset,
      sort,
    },
  })
  return response.data
}

async function postInit(): Promise<any> {
  const response = await api.post(endpointsFlow.postInit)
  return response.data
}

export const flowApi = {
  getRpaFlow,
  postInit,
}
