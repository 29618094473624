import React from 'react';

import { IconDownload } from 'assets/IconDownload';
import { IconFilter } from 'assets/IconFilter';
import { IconTrash } from 'assets/IconTrash';
import { IconUpdate } from 'assets/IconUpdate';
import { Table } from 'components/Table';
import { IDataFlow } from 'domains/Flow/flowTypes';
import { useGetRpaFlow } from 'domains/Flow/useCase/useGetRpaFlow';
import moment from 'moment';

export const Resumo = () => {
  const { flows } = useGetRpaFlow({
    page: 0,
    offset: 10,
    sort: 'init_flow'
  });

  function parseStatus(status: string): string {
    switch (status) {
      case 'RUNNING':
        return 'Em andamento';
      case 'STOPPED':
        return 'Parado';
      case 'CANCELED':
        return 'Cancelado';
      case 'FINISHED':
        return 'Finalizado';
      default:
        return 'Status desconhecido';
    }
  }

  return (
    <div
      className="pt-6 pb-6 pl-6 pr-6 ml-6 mr-6 rounded bg-white"
      style={{ border: '1px solid #DFE4E9' }}
    >
      <div className="flex justify-between items-start">
        <div>
          <p className="font-medium">Resumo de Operações</p>
          <span className="font-normal text-sm text-gray-700">
            Últimas operações realizadas no sistema.
          </span>
        </div>

        <div className="flex gap-3 items-center">
          <button className="h-11 px-3 py-3 border border-solid border-gray-300">
            <IconDownload />
          </button>

          <button className="h-11 px-3 py-3 border border-solid border-gray-300">
            <IconUpdate />
          </button>

          <button className="h-11 flex gap-1 items-center px-3 py-3 border border-solid border-gray-300">
            <IconFilter />
            <span className="font-medium">Filtrar por</span>
          </button>

          {/* <button className="h-11 flex gap-1 items-center px-3 py-3 border border-solid border-gray-300">
            <IconTrash />
            <span className="font-medium">Limpar</span>
          </button> */}
        </div>
      </div>

      <Table
        columns={[
          { label: 'Cancelamentos Totais', column: 'renovacoes_totais' },
          { label: 'Sucesso', column: 'sucesso' },
          { label: 'Erro', column: 'error' },
          { label: 'Início Cancelamento', column: 'inicio_renovacao' },
          { label: 'Fim Cancelamento', column: 'fim_renovacao' },
          { label: 'Status', column: 'status' }
        ]}
        data={
          flows
            ? flows.data.map((item: IDataFlow) => ({
                renovacoes_totais: item.totalFlowAssets,
                sucesso: item.totalFlowAssetsSuccess,
                error: item.totalFlowAssetsErrors,
                inicio_renovacao: moment(item.initFlow).format(
                  'DD/MM/YYYY HH:mm:ss'
                ),
                fim_renovacao:
                  item.endFlow !== null
                    ? moment(item.endFlow).format('DD/MM/YYYY HH:mm:ss')
                    : null,
                status: parseStatus(item.status)
              }))
            : []
        }
      />
    </div>
  );
};
