/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@intraversa-lab/styleguide'

import { actionApi } from './actionApi'
import {
  IPayloadUnitaryRenewal,
  IResponseAction,
  IResponseDownload,
  IResponseModelCSV,
  IResponseUnitaryRenwal,
} from './actionTypes'

async function getRpaAction({
  page = 0,
  offset = 10,
  sort = 'created_at',
}: {
  page: number
  offset: number
  sort?: string
}): Promise<IResponseAction> {
  try {
    const respose = await actionApi.getRpaAction({
      page,
      offset,
      sort,
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar RPAs.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getAllRpaAction({
  value = '',
  finalDate = '',
  initialDate = '',
  status = '',
  page = 0,
  offset = 10,
  sort = 'created_at',
}: {
  value?: string
  finalDate?: string
  initialDate?: string
  status?: string
  page?: number
  offset?: number
  sort?: string
}): Promise<IResponseAction> {
  try {
    const respose = await actionApi.getFindAllRpaAction({
      value,
      finalDate,
      initialDate,
      status,
      page,
      offset,
      sort,
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar RPAs.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function postRpaActionUnitaryRenewal(
  payload: IPayloadUnitaryRenewal,
): Promise<IResponseUnitaryRenwal[]> {
  try {
    const response = await actionApi.postRpaActionUnitaryRenewal(payload)

    return response
  } catch (error) {
    console.log(error)
    toast({
      type: 'error',
      text: error.response.data.message,
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getDownloadRpaAction(): Promise<IResponseDownload> {
  try {
    const response = await actionApi.getDownloadAction()

    return response
  } catch (error) {
    console.log(error)
    toast({
      type: 'error',
      text: error.response.data.message,
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getModelCSV(): Promise<IResponseModelCSV> {
  try {
    const response = await actionApi.getModelCSV()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

async function postUploadCSV(file: File): Promise<IResponseModelCSV> {
  try {
    const response = await actionApi.postUploadCSV(file)

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

async function postCleanAll(): Promise<any> {
  try {
    const response = await actionApi.postCleanAll()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

export const actionService = {
  getRpaAction,
  getAllRpaAction,
  postRpaActionUnitaryRenewal,
  getDownloadRpaAction,
  getModelCSV,
  postUploadCSV,
  postCleanAll,
}
