import React, { useEffect, useState } from 'react';

import { Button, toast, useConfirmModal } from '@intraversa-lab/styleguide';
// import { IconClose } from 'assets/IconClose'
import { IconDownload } from 'assets/IconDownload';
import { IconPlus } from 'assets/IconPlus';
import { IconStatus } from 'assets/IconStatus';
import { IconTrash } from 'assets/IconTrash';
import { IconUpdate } from 'assets/IconUpdate';
import { IconUpload } from 'assets/IconUpload';
import { ButtonInfo } from 'components/ButtonInfo/ButtonInfo';
import { FilterTo } from 'components/FilterTo/FilterTo';
import { ModalUnitaryRenawel } from 'components/ModalUnitaryRenawel';
import { ModalUpload } from 'components/ModalUpload/ModalUpload';
import { Table } from 'components/Table';
import { queryClient } from 'configs/queryClient';
import { IdataAction } from 'domains/ActionConfig/actionTypes';
import { useGetAllRpaAction } from 'domains/ActionConfig/useCases/useGetAllAction';
import { useGetDownloadAction } from 'domains/ActionConfig/useCases/useGetDownloadAction';
import { usePostCleanAll } from 'domains/ActionConfig/useCases/usePostCleanAll';
import { usePostInit } from 'domains/Flow/useCase/usePostinit';
import { QueryKeys } from 'infra';
import { downloadDocumentNavigator } from 'utils/downlaodCSV';

export const Assinaturas = () => {
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const [modalRenovacao, setModalRenovacao] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [paginationProps, setPaginationProps] = useState({
    offset: 10,
    page: 0,
    total: 1
  });

  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal();

  const { onCleanAll } = usePostCleanAll({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      toast({
        text: 'Sucesso',
        description: 'Dados da tabela deletado com sucesso',
        type: 'success'
      });
    }
  });
  const { actions, refetch } = useGetAllRpaAction({
    page: paginationProps.page,
    offset: paginationProps.offset,
    sort: 'created_at'
  });

  const { onDownload, isLoading: isLoadingDownload } = useGetDownloadAction({
    onSuccess: data => {
      downloadDocumentNavigator(data.base64, 'assinaturas');
    }
  });
  const { onInit, isLoading: isLoadingInit } = usePostInit({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
    }
  });

  function parseStatus(status: number): string {
    switch (status) {
      case 0:
        return 'Pendente';
      case 200:
        return 'Sucesso';
      default:
        return 'Erro';
    }
  }

  function parseStatusColor(status: number): string {
    switch (status) {
      case 0:
        return 'text-xs font-medium bg-gray-200 text-gray-700 rounded-md pt-[2px] pb-[2px]';
      case 200:
        return 'text-xs font-medium bg-green-100 text-green-900 rounded-md pt-[2px] pb-[2px]';
      default:
        return 'text-xs font-medium bg-red-100 text-red-600 rounded-md pt-[2px] pb-[2px]';
    }
  }

  useEffect(() => {
    if (actions) {
      setPaginationProps(old => ({ ...old, total: actions.total }));
    }
  }, [actions]);

  return (
    <div
      className="pt-6 pb-6 pl-6 pr-6 ml-6 mr-6 rounded bg-white"
      style={{ border: '1px solid #DFE4E9' }}
    >
      <div>
        <p className="font-medium">Inserir cancelamento</p>
        <span className="font-normal text-sm text-gray-700">
          Insira dados na tabela via arquivo CSV ou adição unitária de
          renovação.
        </span>

        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center gap-3 ">
            <Button
              variant="outlined"
              className="flex gap-2"
              onClick={() => setModalUpload(!modalUpload)}
              style={{ textTransform: 'none' }}
            >
              <IconUpload />
              Upload arquivoc CSV
            </Button>
            <Button
              variant="outlined"
              onClick={() => setModalRenovacao(!modalRenovacao)}
              className="flex gap-2"
              style={{ textTransform: 'none' }}
            >
              <IconPlus />
              Cancelamento unitária
            </Button>
            <Button
              variant="contained"
              style={{ textTransform: 'none' }}
              disabled={isLoadingInit}
              onClick={() =>
                updateModalStateAndOpenModal({
                  title: 'Executar renovação',
                  description:
                    'Ao confirmar essa ação você irá executar a renovação de todos usuários da tabela. Deseja continuar?',
                  onConfirm: () => onInit(),
                  maxWidth: 441,
                  type: 'light'
                })
              }
            >
              Executar
            </Button>
          </div>

          <div className="flex gap-3 items-center">
            <button
              className="h-11 px-3 py-3 border border-solid border-gray-300 disabled:opacity-50"
              onClick={onDownload}
              disabled={isLoadingDownload}
            >
              <IconDownload />
            </button>

            <button className="h-11 px-3 py-3 border border-solid border-gray-300">
              <IconUpdate />
            </button>

            <FilterTo
              filterBy={v => setFilterBy(v)}
              options={['Pendente', 'Sucesso', 'Erro']}
            />

            <button
              className="h-11 flex gap-1 items-center px-3 py-3 border border-solid border-gray-300"
              onClick={() =>
                updateModalStateAndOpenModal({
                  title: 'Limpar dados da tabela',
                  description:
                    'Ao confirmar essa ação você irá limpar todos os dados da tabela. Deseja continuar?',
                  onConfirm: () => onCleanAll(),
                  maxWidth: 441,
                  type: 'light'
                })
              }
            >
              <IconTrash />
              <span className="font-medium">Limpar</span>
            </button>
          </div>
        </div>
      </div>

      <Table
        columns={[
          { label: 'Nome', column: 'nome', align: 'left' },
          { label: 'Email', column: 'email', align: 'left' },
          { label: 'Assinatura Atual', column: 'assinatura' },
          { label: 'Status', column: 'status' },
          { label: 'Resposta Pagar.me', column: 'resposta_pagar' }
        ]}
        paginable
        paginationProps={paginationProps}
        handleChangePage={page => setPaginationProps(old => ({ ...old, page }))}
        handleChangeRowsPerPage={offset =>
          setPaginationProps(old => ({ ...old, offset }))
        }
        data={
          actions
            ? actions.data.map((a: IdataAction) => ({
                nome: a.clientName,
                email: a.clientEmail,
                assinatura: a.subscription,
                novo_plano: a.newPlanId,
                status: (
                  <p className={parseStatusColor(a.statusCodeApi)}>
                    {parseStatus(a.statusCodeApi)}
                  </p>
                ),
                // resposta_pagar: a.responseApi,
                resposta_pagar:
                  parseStatus(a.statusCodeApi) === 'error' ? (
                    <div className="flex justify-center">
                      <ButtonInfo description={a.responseApi} />
                    </div>
                  ) : (
                    <span></span>
                  ),
                conta: a.recipientId,
                nova_assinatura: (
                  <p className="flex justify-center ">
                    {a.newSubscription ? <IconStatus /> : null}
                  </p>
                )
              }))
            : []
        }
      />

      {modalRenovacao && (
        <ModalUnitaryRenawel
          isOpen={modalRenovacao}
          onClose={() => setModalRenovacao(!modalRenovacao)}
        />
      )}

      {modalUpload && (
        <ModalUpload
          isOpen={modalUpload}
          onClose={() => setModalUpload(!modalUpload)}
          onSuccess={() => refetch()}
        />
      )}

      {renderModal()}
    </div>
  );
};
